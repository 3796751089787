$theme-colors: (
    'primary': #0ea51d,
    'secondary': #9E9E9E,
    'danger': #DF5753
);

$body-gb: #e5e5e5;
$body-color: #263238;

@import '~bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: unset;
}

.base-card {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.base-input {
  border-radius: 5px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 40px;
}

.base-input::placeholder {
  color: #9e9e9e;
}

.btn-crud-add {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.015em;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .base-input {
    height: 50px;
  }
}

@media (min-width: 768px) {
  .btn-crud-add {
    max-width: max-content;
    margin-bottom: 0;
  }
}
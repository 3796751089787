.prod-data {
    display: flex;
    align-items: center;
    justify-self: start;
}
.login-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    font-size: 16px;
    height: 100%;
}

.login-card-container h1 {
    margin: 60px 0 10px 0;
    font-weight: normal;
    font-size: 1.7em;
    text-align: center;
    letter-spacing: -0.015em;
    color: #263238;
    text-transform: uppercase;
}

.input-email {
    margin-bottom: 16px;
    display: inline;
    justify-self: unset;
}

.input-password {
    margin-bottom: 8px;
}

.select-container{
    display: flex;
    margin: 30px;
}

.input-deli{
    margin-right: 20px;
}

.login-link-recover {
    margin-bottom: 10%;
    font-size: 0.8em;
    letter-spacing: -0.015em;
    color: #407bff;
    display: block;
    margin-bottom: 50px;
}

.login-link-register {
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    letter-spacing: -0.015em;
    text-decoration-line: underline;
    color: #407bff;
    margin-left: 10px;
}

.login-submit {
    margin-bottom: 10px;
}

.login-submit button {
    width: calc(100% - 35px);
    font-size: 1em;
}

.not-registered {
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    letter-spacing: -0.015em;
    color: #9e9e9e;
}

.signup-container {
    font-size: 11px;
    text-align: center;
}

.alert-danger {
    padding: 10px;
    font-size: 12px;
    margin-bottom: 15px;
}

@media (max-height: 600px) {
    .login-card-container {
        height: 100%;
        justify-content: space-evenly;
    }

    .login-card-container h1 {
        margin: 30px 0 20px 0;
    }

    .login-link-recover {
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) {
    .login-card-container {
        padding: 20px 50px;
    }

    .login-card-container h1 {
        font-size: 2.2em;
    }

    .login-link-recover {
        font-size: 16px;
    }

    .input-email {
        margin-bottom: 20px;
    }

    .input-password {
        margin-bottom: 15px;
    }
}
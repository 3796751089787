.user-crud-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.user-crud-content {
  max-width: 1200px;
}

@media (min-width: 768px) {
  .user-crud-bar-container {
    display: flex;
    margin-bottom: 30px;
  }
}

.catalog-loader-container {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px 0;
  align-items: center;
}

.catalog-loader {
  display: flex;
  padding: 10px;
}

.admin-container {
  display: flex;
  flex-direction: column;
}

.admin-content-container {
  padding: 0 20px 20px;
}

@media (min-width: 768px) {
  .admin-content-container {
    padding: 0 40px 40px;
  }
}

@media (min-width: 1200px) {
  .admin-container {
    flex-direction: row;
  }

  .admin-content-container {
    flex: 1;
    padding: 40px;
  }
}

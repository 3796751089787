.product-filter-container {
  width: 100%;
  padding: 20px;
}

.product-filter-name-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 10px;
}

.product-filter-name-container input {
  border: none;
}

.product-filter-name-container input:focus {
  box-shadow: none;
}

.product-filter-name-container input::placeholder {
  color: #9e9e9e;
}

.product-filter-search-icon {
  border: none;
  background-color: unset;
}

.product-filter-category-container {
  flex: 1;
  margin-right: 10px;
}

.btn-product-filter-clear {
  width: 100%;
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px;
}

.product-filter-select__control {
  margin-bottom: 16px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  border: none !important;
  border-bottom: 1px solid #e1e1e1 !important;
}

.product-filter-select__placeholder {
  color: #9e9e9e !important;
}

.product-filter-select__indicator-separator {
  display: none;
}

@media (min-width: 576px) {
  .product-filter-botton-container {
    display: flex;
  }

  .btn-product-filter-clear {
    width: auto;
  }

  .product-filter-select__control {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .product-filter-container {
    flex: 1;
    padding: 10px 15px;
  }

  .product-filter-form {
    display: flex;
  }

  .product-filter-name-container {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .product-filter-botton-container {
    flex: 1;
  }

  .btn-product-filter-word {
    display: none;
  }
}

@media (min-width: 992px) {
  .btn-product-filter-word {
    display: unset;
  }
}

.proposal-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
  }
  
  .base-card-proposal {
    width: 80%;
    margin-top: 20px;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
  }
  
  .product-details-card {
    display: flex;
    flex-direction: column;
  }
  
  .proposal {
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  
  .proposal > div {
    margin-bottom: 10px;
  }
  
  .proposal > div > strong {
    margin-left: 10px;
  }
 
.product-price-container {
  display: flex;
}

.product-price-container span {
  font-size: 18px;
  color: #9e9e9e;
  margin-top: 4px;
  font-weight: bold;
}

.product-price-container h3 {
  font-size: 32px;
  color: #407bff;
  margin-left: 10px;
}

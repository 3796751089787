.product-crud-form-card {
  padding: 20px;
}

.product-crud-form-title {
  font-weight: bold;
  font-size: 22px;
  letter-spacing: -0.015em;
  color: #263238;
  margin-bottom: 30px;
}

.margin-botton-30 {
  margin-bottom: 30px;
}

.product-crud-inputs-container {
  margin-bottom: 30px;
}

.product-crud-inputs-left-container {
  margin-bottom: 30px;
}

.product-crud-buttons-container {
  display: flex;
  justify-content: space-between;
}

.product-crud-button {
  width: 110px;
  height: 50px;
  border-radius: 10px;
  font-weight: bold;
}

.product-crud-select__control {
  border-radius: 5px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 50px;
}

.product-crud-select__placeholder {
  color: #9e9e9e !important;
}

.product-crud-select__indicator-separator {
  display: none;
}

.product-crud-button-add-image button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.product-crud-button-add-image p {
  color: #407BFF;
  font-size: 12px;
}

.product-crud-upload-image-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 255px;
  background-color: #263238;
  border-radius: 10px;
}

.product-crud-cancel-upload {
  width: 37px;
  padding: 0;
  background-color: transparent;
  margin-top: 30px;
}

.product-crud-upload-image-card button svg {
  width: 35px;
  height: 35px;
}

.product-crud-bar-upload-image {
  width: 100%;
  height: 7px;
  background-color: #E1E1E1;
  border-radius: 100px;
  position: relative;
}

.product-crud-bar-upload-image div {
  background-color: #407BFF;
  border-radius: 100px;
  position: absolute;
  height: 100%;
  animation: progress-animation 10s forwards;
}

.prod-cont {
  width: 100%;
  height: 100%;
}

@keyframes progress-animation {
  0% {
    width: 0%;
  }

  100% {
    width: 95%
  }
}

@media (min-width: 576px) {
  .product-crud-buttons-container {
    justify-content: flex-end;
  }

  .product-crud-button {
    width: 150px;
    margin-left: 30px;
  }
}

@media (min-width: 992px) {
  .product-crud-inputs-left-container {
    margin-bottom: 0;
  }

  .product-crud-button {
    width: 170px;
  }
}
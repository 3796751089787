.product-crud-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  height: 100%;
}
.formatted-text {
  white-space: pre-line;
}

.product-crud-card-top-container {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid #e1e1e1;
}

.product-crud-card-top-container img {
  max-width: 100%;
  max-height: 100%;
}

.product-crud-card-button-container {
  text-align: left;
  padding: 15px 20px 30px 20px;
}

.product-crud-card-button-container h6 {
  font-size: 18px;
  color: #263238;
}

.product-crud-categories-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.product-crud-card-buttons-container {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.product-crud-card-button {
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.015em;
  border-radius: 10px;
  width: 110px;
  height: 40px;
}

.product-crud-card-button-first {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .product-crud-card {
    flex-direction: row;
    padding-bottom: 0;
  }

  .product-crud-card-content {
    display: flex;
  }

  .product-crud-card-buttons-container {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .product-crud-card-button-first {
    margin-right: 0;
    margin-top: 22px;
  }

  .product-crud-card-button {
    width: 140px;
  }

  .product-crud-card-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .product-crud-card-top-container {
    border-bottom: 0;
    width: 200px;
    height: 158px;
    border-right: 1px solid #e1e1e1;
  }
}

@media (min-width: 992px) {
  .product-crud-card-button {
    width: 220px;
  }
}

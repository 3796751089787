.auth-container {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  min-height: 90vh;
  background-image: url('assets/images/backgroud-auth.svg');
}

.auth-banner-container {
  display: none;
}

.auth-form-container {
  width: 100%;
  max-height: 600px;
}

@media (max-height: 600px) {
  .auth-container {
    min-height: 85vh;
  }
}

@media (min-width: 576px) {
  .auth-container {
    padding: 30px;
  }

  .auth-form-container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .auth-form-container {
    width: 550px;
  }
}

@media (min-width: 1200px) {
  .auth-container {
    height: 90vh;
    padding: 30px 40px;
    background-position-x: 60%;
    background-position-y: 65%;
  }

  .auth-banner-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-top: 15px;
  }

  .auth-banner-container h1 {
    font-size: 40px;
    letter-spacing: -0.015em;
    color: #263238;
    margin: 0 50px 15px 50px;
  }

  .auth-banner-container p {
    font-size: 16px;
    width: 400px;
    margin-left: 50px;
    letter-spacing: -0.015em;
    color: #9e9e9e;
    margin-bottom: 0;
  }

  .auth-banner-container svg {
    width: 100%;
    height: auto;
  }

  .auth-form-container {
    width: 450px;
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .auth-container {
    background-size: 110%;
    background-position: 100%;
  }

  .auth-banner-container {
    width: 750px;
  }

  .auth-banner-container h1 {
    width: 100%;
    font-size: 40px;
    margin: 0 0 5px 0;
  }

  .auth-banner-container p {
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 18px;
  }

  .auth-banner-container svg {
    height: 850%;
  }

  .auth-form-container {
    width: 450px;
    margin-left: 20px;
  }
}

.user-crud-form-card {
  padding: 20px;
}

.user-crud-form-title {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.015em;
  color: #263238;
  margin: 10px 0 30px;
}

.margin-botton-30 {
  margin-bottom: 30px;
}

.user-crud-input,
.user-crud-input-email {
  margin-bottom: 30px;
}

.user-crud-input-password-container {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 40px;
  border: 1px solid #ced4da;
  margin: -2px;
  padding: 4px;
}

.user-crud-input-password-container input::placeholder {
  color: #9e9e9e;
}

.user-crud-input-password {
  border: none;
  border-radius: 1px 0 0 1px;
  height: 99%;
}

.user-crud-reveal-password-icon {
  width: auto;
  padding: 0 5px 0 7px;
  border-left: 2px solid #e1e1e1;
}

.user-crud-reveal-password-icon button {
  background: transparent;
  padding: 0;
  border: none;
}

.user-crud-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.user-crud-button {
  width: 100%;
  max-width: 270px;
  height: 50px;
  border-radius: 10px;
  font-weight: bold;
}

.user-crud-buttons-container button:first-child {
  margin-right: 15px;
}

.user-crud-select__control {
  border-radius: 5px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 40px !important;
}

.user-crud-select__placeholder {
  color: #9e9e9e !important;
}

.user-crud-select__indicator-separator {
  display: none;
}

@media (min-width: 576px) {
  .user-crud-input-password-container {
    height: 50px;
  }

  .user-crud-buttons-container {
    justify-content: flex-end;
  }

  .user-crud-buttons-container button:first-child {
    margin-right: 30px;
  }
}

@media (min-width: 768px) {
  .user-crud-form-card {
    padding: 40px;
  }

  .user-crud-form-title {
    font-size: 22px;
  }

  .user-crud-inputs-content {
    display: flex;
    justify-content: space-between;
  }

  .user-crud-input {
    width: 100%;
  }

  .user-crud-input-email {
    margin-bottom: 34px;
  }

  .user-crud-input:first-child {
    margin-right: 30px;
  }
}

@media (min-width: 1200px) {
  .user-crud-form-title {
    margin: 35px 0 40px;
  }
}

.admin-nav-itens-container {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.admin-nav-container li {
  width: 25%;
}

.admin-nav-container {
  padding: 10px;
  background-color: white;
}

.admin-nav-item {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: #f2f2f2;
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.015em;
  color: #9e9e9e;
}

.admin-nav-item.active {
  background-color: rgba(64, 123, 255, 0.3);
  color: #407bff;
}

.admin-nav-item p {
  margin: 0;
}

@media (max-width: 319px) {
  .admin-nav-item {
    height: 20px;
    font-size: 10px;
  }
}

@media (min-width: 320px) {
  .admin-nav-item p {
    font-size: 0.8em;
  }
}

@media (min-width: 576px) {
  .admin-nav-container {
    padding: 20px 30px;
  }

  .admin-nav-item p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .admin-nav-container ul {
    justify-content: unset;
    flex-direction: column;
  }

  .admin-nav-container li {
    width: 100%;
  }

  .admin-nav-container {
    width: 300px;
    height: calc(100vh - 70px);
    padding: 0;
  }

  .admin-nav-item {
    height: 80px;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    justify-content: flex-start;
    padding: 0;
    padding-left: 40px;
  }

  .admin-nav-item p {
    font-size: 18px;
  }
}
